"use client"
import React, { useEffect } from 'react';

import { NavbarDefault } from 'components/navigation/NavbarDefault';
import { AuthCard } from 'features/auth/AuthCard';
import { useAuthentication } from 'hooks/useAuthentication';
import { setFormIndex } from 'features/auth/authSlice';
import { useDispatch } from 'react-redux'

import isEmpty from 'utilities/isEmpty';
import { useNavigate } from 'hooks/useNavigate';
import { useSearchParams } from 'next/navigation';
import Paths from 'lib/routes/Paths';
import { AuthForms } from 'utilities/constants';

const Login = () => {
  const { isAuthenticated, user } = useAuthentication();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = useSearchParams();

  useEffect(() => {
    let passwordResetToken = searchParams.get('token');
    if (!isEmpty(passwordResetToken)) {
      dispatch(setFormIndex(AuthForms.RESET_PASSWORD));
    }
    else if (!isAuthenticated) {
      dispatch(setFormIndex(0));
    }
    return function cleanup() {

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (isAuthenticated) {
      let returnUrl = searchParams.get('returnUrl')

      if (!isEmpty(returnUrl)) {
        navigate(returnUrl);
      }
      else {
        navigate(Paths.PUBLIC.ROOT.HOME);
      }
    }

    return function cleanup() {

    };
  }, [dispatch, isAuthenticated, user, navigate, searchParams]);

  return (
    <>
      <NavbarDefault />
      <div className="container mx-auto md:mt-12 flex items-center justify-center">
        <AuthCard isLoginPage={true} />
      </div>
    </>
  );
};

export default Login;
